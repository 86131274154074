import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputLabel from "@material-ui/core/InputLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as csx from "csx"
import type { ChangeEvent } from "react"

import Typography from "../typography/Typography"
import BaseInput, { BaseInputProps, BaseInputState } from "./BaseInput"

const styles = () =>
  createStyles({
    formLabel: {
      whiteSpace: "nowrap"
    },
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    group: {
      marginTop: "1rem"
    },
    inputRoot: {
      color: RadioField.theme.colors.baseGray.toString(),
      paddingRight: "9px"
    },
    formControlLabelRoot: {
      marginRight: "26px"
    },
    spacingTight: {
      height: "2rem"
    },
    labelDesc: {
      display: "block",
      marginBottom: "0.5rem"
    },
    disabled: {
      color: csx.important(RadioField.theme.colors.disabled.toString())
    }
  })

export interface RadioOption {
  label: string | JSX.Element
  labelPlacement?: "end" | "start" | "top" | "bottom"
  description?: string
  value: string
}

export interface RadioFieldProps extends BaseInputProps {
  options: RadioOption[]
  variant?: "default" | "bold"
  name?: string
  direction?: "vertical" | "horizontal"
  margin?: boolean
  spacing?: "default" | "tight"
  disabled?: boolean
  labelPlacement?: "end" | "start" | "top" | "bottom"
  onChange?: (value: string) => void
  noGroupMargin?: boolean
}

interface RadioFieldState extends BaseInputState {
  value: string | null
}

class RadioField extends BaseInput<RadioFieldProps & WithStyles<typeof styles>, RadioFieldState> {
  constructor(props) {
    super(props)

    this.state = { value: props.value ?? null }
  }

  render() {
    const { classes, className, label, direction, margin, noGroupMargin, name, disabled } = this.props

    return (
      <FormControl
        component="fieldset"
        className={classNames(margin !== false && classes.margin, disabled && classes.disabled, className)}
      >
        {label && (
          <InputLabel shrink className={classNames(classes.formLabel, disabled && classes.disabled)}>
            {label}
          </InputLabel>
        )}
        <RadioGroup
          row={direction === "horizontal"}
          aria-label={label}
          name={name}
          className={noGroupMargin ? undefined : classes.group}
          value={this.state.value}
          onChange={this.onChange}
        >
          {this.renderOptions()}
        </RadioGroup>
      </FormControl>
    )
  }

  protected renderOptions() {
    const { labelPlacement = "end", classes, variant, spacing, disabled } = this.props

    return this.props.options.map((option, i) => {
      const label = [
        <FormControlLabel
          value={option.value}
          key={option.value}
          disabled={disabled}
          classes={{
            root: classes.formControlLabelRoot
          }}
          control={
            <Radio
              classes={{
                root: classNames(classes.inputRoot, spacing === "tight" && classes.spacingTight)
              }}
            />
          }
          label={
            <Typography
              component="span"
              disabled={disabled}
              variant={variant === "bold" && !disabled ? "bold" : "body"}
            >
              {option.label}
            </Typography>
          }
          labelPlacement={labelPlacement}
        />
      ]
      if (option.description) {
        label.push(
          <Typography variant="description" className={classes.labelDesc} key={option.value + "description"}>
            {option.description}
          </Typography>
        )
      }
      return label
    })
  }

  private onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const value = event.target.value

    this.setState({ value }, () => onChange?.(value))
  }
}

export default withStyles<any, any, RadioFieldProps>(styles)(RadioField)
