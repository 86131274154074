import MomentUtils from "@date-io/moment"
import FormHelperText from "@material-ui/core/FormHelperText"
import IconButton from "@material-ui/core/IconButton"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ParsableDate } from "@material-ui/pickers/constants/prop-types"
import classNames from "classnames"
import * as csx from "csx"
import { Moment } from "moment"

import { ViewComponent } from "../base/ViewComponent"

const styles = ({ palette, typography }: Theme) =>
  createStyles({
    root: {
      "& .MuiInput-underline:before": { borderBottom: 0 },
      "& .MuiInput-underline:after": { borderBottom: 0 }
    },
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    // Week selection
    dayWrapper: {
      position: "relative"
    },
    day: {
      width: 36,
      height: 36,
      fontSize: typography.caption.fontSize,
      margin: "0 2px",
      color: "inherit"
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${palette.secondary.main}`,
      borderRadius: "50%"
    },
    nonCurrentMonthDay: {
      color: palette.text.disabled
    },
    disabled: {
      color: palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
      color: "#676767"
    },
    highlight: {
      background: palette.primary.main,
      color: palette.common.white
    },
    firstHighlight: {
      extend: "highlight",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%"
    },
    endHighlight: {
      extend: "highlight",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%"
    },
    label: {
      textTransform: "uppercase",
      fontSize: csx.important("0.9rem"),
      marginLeft: "0.5rem",
      letterSpacing: "1.1px",
      whiteSpace: "nowrap"
    }
  })

interface DateFieldProps {
  className?: string
  disabled?: boolean
  helperText?: string
  id: string
  label?: string
  locale?: string
  margin?: boolean
  maxDate?: Moment
  minDate?: Moment
  minDateMessage?: string
  mode?: "day" | "week"
  name?: string
  onChange?: (date: Moment, context) => void
  placeholder?: string
  required?: boolean
  value: ParsableDate
}

class DateField extends ViewComponent<DateFieldProps & WithStyles<typeof styles>, {}> {
  get componentName() {
    return ["ui", "form", "DateField"]
  }

  render() {
    const { className, classes, helperText, value, margin, label, mode, minDate, minDateMessage, maxDate, disabled } =
      this.props

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={this.localization.currentLanguage}>
        <DatePicker
          variant="inline"
          label={label}
          value={value}
          minDate={minDate}
          minDateMessage={minDateMessage}
          maxDate={maxDate}
          disabled={disabled}
          format="DD.MM.YYYY"
          InputLabelProps={{ className: classes.label }}
          className={classNames(classes.root, margin !== false && classes.margin, className)}
          renderDay={mode === "week" ? this.renderWrappedWeekDay : undefined}
          labelFunc={mode === "week" ? this.formatWeekSelectLabel : undefined}
          onChange={this.onChange}
          onOpen={() => setTimeout(this.setZIndex, 0)}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </MuiPickersUtilsProvider>
    )
  }

  formatWeekSelectLabel = (date, invalidLabel) => {
    if (date === null) {
      return ""
    }

    return date ? this.txt("week").replace("{0}", date.isoWeek()).replace("{1}", date.format("YYYY")) : invalidLabel
  }

  renderWrappedWeekDay = (date: Moment, selectedDate: Moment, dayInCurrentMonth: boolean) => {
    const { classes, minDate, maxDate } = this.props

    const start = selectedDate.clone().startOf("isoWeek")
    const end = selectedDate.clone().endOf("isoWeek")

    const dayIsBetween = date.isBetween(start, end, "day", "[]")
    const isFirstDay = date.isSame(start, "day")
    const isLastDay = date.isSame(end, "day")

    const dayIsBeforeMin = minDate && date.isBefore(minDate)
    const dayIsAfterMax = maxDate && date.isAfter(maxDate)

    const wrapperClassName = classNames({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    })

    const dayClassName = classNames(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.disabled]: dayIsBeforeMin || dayIsAfterMax
    })

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {date.format("D")} </span>
        </IconButton>
      </div>
    )
  }

  private onChange = (date: Moment) => this.props.onChange?.(date, this.context)

  private setZIndex = () => {
    const picker: any = document.querySelector("#picker-popover")

    if (picker && picker.style) picker.style.zIndex = 11000
  }
}

export default withStyles(styles)(DateField)
